/* eslint-disable @typescript-eslint/no-unused-vars */
// packages
import { Box, Button, Card, Center, Flex, FormControl, FormLabel, Grid, GridItem, Icon, IconButton, Input, Spinner, Tag, Textarea, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { postClassify, client } from './core/domains';
import getConfig from './getConfig';
import IAPage from './IAPage';

const config = getConfig();

// configure internal service client
client.setConfig({
  // set default base url for requests
  baseUrl: config?.api?.url,
});

interface IResult {
  code: string;
  encoding: string;
  date?: string;
  extract: string;
}

const MicroIcon = (props) => {
  return (
    <Icon viewBox="0 0 384 512" {...props}>
      <path d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z" />
    </Icon>
  )
}

const Result = ({ result }: { result: IResult }) => {
  return (
    <Card p="8px" gap="8px">
      <quote>
        {result.extract}
      </quote>
      <Flex gap="16px">
        <Tag colorScheme="blue">Base: {result.encoding}</Tag>
        <Tag colorScheme="green">Code: {result.code}</Tag>
        {result.date && (
          <Tag>Date: {result.date}</Tag>
        )}
      </Flex>
    </Card>
  )
}

const IAPageOld = ({ token }: { token: string }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<IResult[]>([/* {
    "code": "xxx",
    "encoding": "CIM10",
    "date": "2024-06-13",
    "extract": "dans mon cabinet suite à des douleurs au dos"
  }, {
    "code": "xxx",
    "encoding": "xxx",
    "date": "2014",
    "extract": "BTP en tant que charpentier depuis 10 ans"
  }, {
    "code": "xxx",
    "encoding": "xxx",
    "extract": "Il porte une prothèse de hanche"
  }, {
    "code": "xxx",
    "encoding": "VIDAL",
    "extract": "2 comprimés de 200mg d'ibuprofène"
  } */]);

  const onClassify = async (inputString: string) => {
    try {
      const { data, error } = await postClassify({
        // setting headers per request
        body: {
          input: inputString,
        },
        /* headers: {
          Authorization: `Bearer ${token}`,
        }, */
      });
      setLoading(false);
      if (error) {
        toast({
          title: 'Une erreur est survenue',
          description: error?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return;
      }
      setResults(data!);
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Une erreur est survenue',
        description: e?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
  };

  const onTextChange = (inputString: string) => {
    setLoading(true);
    onClassify(inputString);
  }

  const debouncedClassify = useDebouncedCallback(onTextChange, 1000);

  return (
    <Grid
      w="full"
      h="full"
      minH="100vh"
      bg="gray.100"
      gridTemplateColumns="1fr 1fr"
    >
      <GridItem p="16px" pr="8px">
        <Box bg="white" w="full" h="full" borderRadius="16px" p="8px">
          <Flex w='full' justify="space-between">
            <IconButton icon={<MicroIcon />} />
            {loading && <Spinner size="lg" />}
          </Flex>
          <Textarea
            resize="none"
            w="full"
            h="calc(100% - 48px)"
            mt="8px"
            onChange={(e) => debouncedClassify(e.target.value)}
          />
        </Box>
      </GridItem>
      <GridItem p="16px" pl="8px" overflowX="scroll">
        <Flex
          bg="white"
          w="full"
          h="full"
          borderRadius="16px"
          p="8px"
          gap="8px"
          flexDir="column"
        >
          {results.map((res) => (
            <Result
              key={`${res.extract}.${res.code}`}
              result={res}
            />
          ))}
        </Flex>
      </GridItem>
    </Grid>
  )
};

const LoginPage = ({
  setLogin
}: {
  setLogin: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => console.log('login', e);
  const isLoading = false;

  return (
    <Center w="full" h="full" minH="100vh" bg="gray.100">
      <Center
        w="320px"
        bg="white"
        p="16px"
        borderRadius="16px"
        gap="16px"
        flexDir="column"
      >
        <FormControl>
          <FormLabel>
            Email
          </FormLabel>
          <Input
            placeholder='Saisissez votre adresse e-mail'
            type='email'
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSubmit(e.key);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            Mot de passe
          </FormLabel>
          <Input
            placeholder='*******'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSubmit(e.key);
            }}
          />
        </FormControl>
        <Button
          w="full"
          isDisabled={!mail || !password}
          isLoading={isLoading}
        >
          Connecter
        </Button>
      </Center>
    </Center>
  )
};

export default () => {
  const [login, setLogin] = useState(true);

  if (login) return (
    <IAPage token={login} />
  );

  return (
    <LoginPage setLogin={setLogin} />
  );
}
