// packages
import { Box, Button, ButtonGroup, Divider, Flex, Grid, GridItem, Icon, IconButton, Image, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, TagCloseButton, TagLabel, Text, Textarea, useBoolean, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { postClassify, client } from './core/domains';
import getConfig from './getConfig';

import { Result as IResult } from './core/domains';
import { CheckIcon, CloseIcon, MinusIcon } from '@chakra-ui/icons';

// assets
import background from "../assets/background.jpg";
import { postSpeech } from './core/domains';

const config = getConfig();

// configure internal service client
client.setConfig({
  // set default base url for requests
  baseUrl: config?.api?.url,
});

const IA_COLOR = "#9468F3";
const IA_BACKGROUND_COLOR = "#E9DEF8";
const IA_ALT_COLOR = "#506482";

const MicroIcon = (props) => {
  return (
    <Icon viewBox="0 0 384 512" {...props}>
      <path d="M192 0C139 0 96 43 96 96l0 160c0 53 43 96 96 96s96-43 96-96l0-160c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 89.1 66.2 162.7 152 174.4l0 33.6-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l72 0 72 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-33.6c85.8-11.7 152-85.3 152-174.4l0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-40z" />
    </Icon>
  )
}

const RecordingIcon = (props) => {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <circle cx="9" cy="9" r="9" fill="#F3BFC4" />
      <circle cx="9" cy="9" r="4" fill="#D62839" />
    </Icon>
  )
}
const StopRecordingIcon = (props) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <rect x="6" y="6" width="12" height="12" rx="2" />
    </Icon>
  )
}

const WidenIcon = (props) => {
  return (
    <Icon viewBox="0 0 512 512" {...props}>
      <path d="M344 0L488 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512L24 512c-13.3 0-24-10.7-24-24L0 344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z" />
    </Icon>
  )
};

const ReduceIcon = (props) => {
  return (
    <Icon viewBox="0 0 512 512" {...props}>
      <path d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8l-144 0c-13.3 0-24-10.7-24-24l0-144c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272l144 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z" />
    </Icon>
  )
}


const AIIcon = (props) => {
  return (
    <Icon viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M18 8L19.25 5.25L22 4L19.25 2.75L18 0L16.75 2.75L14 4L16.75 5.25L18 8Z" fill={props.color} />
      <path d="M18 14L16.75 16.75L14 18L16.75 19.25L18 22L19.25 19.25L22 18L19.25 16.75L18 14Z" fill={props.color} />
      <path d="M10.5 8.5L8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5ZM8.99 11.99L8 14.17L7.01 11.99L4.83 11L7.01 10.01L8 7.83L8.99 10.01L11.17 11L8.99 11.99Z" fill={props.color} />
    </Icon>
  )
}

enum ResultState {
  PENDING,
  VALIDATED,
  DECLINED,
}

const Result = ({ result }: { result: IResult }) => {
  const { extract, code, date, definition } = result;

  const [state, setState] = useState<ResultState>(ResultState.PENDING);
  const [hidden, setHidden] = useState<string[]>([]);

  if (state === ResultState.DECLINED) return (
    <Flex p="8px" gap="32px" justify="space-between" w="full">
      <Flex gap="16px">
        <Tag variant="outline">
          <TagLabel>
            {extract}
          </TagLabel>
        </Tag>
      </Flex>
      <Button
        colorScheme="blue"
        variant="outline"
        onClick={() => setState(ResultState.PENDING)}
      >
        Restaurer
      </Button>
    </Flex>
  );

  if (state === ResultState.VALIDATED) return (
    <Flex p="8px" gap="32px" justify="space-between" w="full">
      <Flex gap="16px">
        {!hidden.includes('extract') && (
          <Tag h="40px">
            <TagLabel>
              {extract}
            </TagLabel>
          </Tag>
        )}
        {code && !hidden.includes('code') && (
          <Tag h="40px">
            <TagLabel>
              {code} {definition}
            </TagLabel>
          </Tag>
        )}
        {date && !hidden.includes('date') && (
          <Tag h="40px">
            <TagLabel>
              {date}
            </TagLabel>
          </Tag>
        )}
      </Flex>
      <Button
        colorScheme="blue"
        variant="link"
      >
        Compléter la saisie
      </Button>
    </Flex>
  );

  return (
    <Flex p="8px" gap="32px" justify="space-between" w="full">
      <Flex gap="16px">
        {!hidden.includes('extract') && (
          <Tag variant="outline" colorScheme="blue">
            <TagLabel>
              {extract}
            </TagLabel>
            <TagCloseButton
              onClick={() => setHidden((h) => [...h, 'extract'])}
            />
          </Tag>
        )}
        {code && !hidden.includes('code') && (
          <Tag variant="outline" colorScheme="blue">
            <TagLabel>
              {code} {definition}
            </TagLabel>
            <TagCloseButton
              onClick={() => setHidden((h) => [...h, 'code'])}
            />
          </Tag>
        )}
        {date && !hidden.includes('date') && (
          <Tag variant="outline" colorScheme="blue">
            <TagLabel>
              {date}
            </TagLabel>
            <TagCloseButton
              onClick={() => setHidden((h) => [...h, 'date'])}
            />
          </Tag>
        )}
      </Flex>
      <ButtonGroup>
        <IconButton
          colorScheme="blue"
          variant="outline"
          icon={<CloseIcon />}
          onClick={() => setState(ResultState.DECLINED)}
        />
        <IconButton
          colorScheme="blue"
          icon={<CheckIcon />}
          onClick={() => setState(ResultState.VALIDATED)}
        />
      </ButtonGroup>
    </Flex>
  )
}

/**
 * The body of the popover containing the AI input and results in separated 
 * panels.
 */
const AIBody = ({
  token,
  isRecording,
  setIsRecording,
  text,
  setText
}: {
  /**
   * Bearer token for query authorization
   */
  token: string;
  /**
   * Recording state of the microphone
   */
  isRecording: boolean;
  /**
   * Recording state modifier
   */
  setIsRecording: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  text: string,
  setText: () => void;
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<IResult[]>([]);
  const [synthesis, setSynthesis] = useState<string[]>([]);

  const onClassify = async (inputString: string) => {
    try {
      const { data, error } = await postClassify({
        // setting headers per request
        body: {
          input: inputString,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      if (error) {
        toast({
          title: 'Une erreur est survenue',
          description: error?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return;
      }
      setResults(data?.extractions
        ?.filter((d) => ["CIM-10", "ROME", "VIDAL"].includes(d.encoding))
        ?? []
      );
      setSynthesis(Array.isArray(data?.synthesis) ? data.synthesis : []);
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Une erreur est survenue',
        description: e?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
  };

  const onTextChange = (inputString: string) => {
    setLoading(true);
    onClassify(inputString);
  }

  const debouncedClassify = useDebouncedCallback(onTextChange, 1000);

  const diseases = results.filter((r) => r.encoding === "CIM-10" || r.encoding === "CIM10");
  const work = results.filter((r) => r.encoding === "ROME");
  const treatments = results.filter((r) => r.encoding === "VIDAL");

  return (
    <Tabs colorScheme="orange" p="16px" h="calc(100% - 56px)">
      <TabList>
        <Tab>Transcription</Tab>
        <Tab>
          <Flex gap="8px" alignItems="center">
            <Text>Saisie proposées</Text>
            {!!results.length && (
              <Box borderRadius="full" color="#000000" bg="orange.400" rounded="full" px="8px">
                {results.length + 0}
              </Box>
            )}
            {!!loading && <Spinner size="sm" />}
          </Flex>
        </Tab>
        <Tab>
          Synthèse
        </Tab>
      </TabList>

      <TabPanels h="calc(100% - 42px)">
        <TabPanel h="100%" p="0">
          <Flex
            w='full'
            gap="8px"
            alignItems="center"
            my="16px"
          >
            <Button
              variant="outline"
              colorScheme="blue"
              leftIcon={isRecording
                ? <StopRecordingIcon fill="blue.600" h="24px" w="24px" />
                : <MicroIcon fill="blue.600" />
              }
              onClick={setIsRecording}
            >
              {isRecording
                ? "Arrêter l'écoute"
                : "Commencer l'écoute"
              }
            </Button>
            {isRecording && (<RecordingIcon w="18px" h="18px" />)}
          </Flex>
          <Textarea
            resize="none"
            w="full"
            h="calc(100% - 72px)"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              debouncedClassify(e.target.value)
            }}
          />
        </TabPanel>

        <TabPanel h="100%" p="0" overflowY="auto">
          <Flex
            w="full"
            gap="8px"
            flexDir="column"
            mt="40px"
          >
            <Flex
              alignItems="center"
              gap="16px"
              mb={diseases.length === 0 && "32px"}
            >
              <Text>Pathologie</Text>
              <Divider borderColor="#BECDD2" />
            </Flex>
            {diseases.map((res) => (
              <Result
                key={`${res.extract}.${res.code}`}
                result={res}
              />
            ))}
            <Flex alignItems="center" gap="16px">
              <Text>Métier</Text>
              <Divider borderColor="#BECDD2" />
            </Flex>
            {work.map((res) => (
              <Result
                key={`${res.extract}.${res.code}`}
                result={res}
              />
            ))}
            <Flex alignItems="center" gap="16px">
              <Text>Traitement</Text>
              <Divider borderColor="#BECDD2" />
            </Flex>
            {treatments.map((res) => (
              <Result
                key={`${res.extract}.${res.code}`}
                result={res}
              />
            ))}
          </Flex>
        </TabPanel>

        <TabPanel h="100%" p="0">
          {synthesis.map((str) => <Text key={str}>{str}</Text>)}
        </TabPanel>

      </TabPanels>
    </Tabs >
  )

  return (
    <Grid
      w="full"
      h="full"
      bg="gray.100"
      gridTemplateColumns="1fr 1fr"
    >
      <GridItem p="16px" pr="8px">
        <Box bg="white" w="full" h="full" borderRadius="16px" p="8px">
          <Flex w='full' justify="space-between">
            <IconButton icon={<MicroIcon />} />
            {loading && <Spinner size="lg" />}
          </Flex>
          <Textarea
            resize="none"
            w="full"
            h="calc(100% - 48px)"
            mt="8px"
            onChange={(e) => debouncedClassify(e.target.value)}
          />
        </Box>
      </GridItem>
      <GridItem p="16px" pl="8px" overflowX="scroll">

      </GridItem>
    </Grid>
  )
};

enum PopoverState {
  CLOSED,
  REDUCED,
  OPEN,
  FULL,
}

const blob2base64 = (blob, mimeType) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrlPrefix = `data:${mimeType};base64,`;
      const base64WithDataUrlPrefix = reader.result;
      const base64 = base64WithDataUrlPrefix.replace(dataUrlPrefix, '');
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const AIPage = ({ token }: { token: string }) => {
  const toast = useToast();
  const [popoverState, setPopoverState] = useState(PopoverState.CLOSED);
  const [isRecording, setIsRecording] = useBoolean();
  const [audioStream, setAudioStream] = useState<MediaStream>(null);
  const [text, setText] = useState<string>('');

  const onSpeech = async (encodedString: string) => {
    try {
      const { data, error } = await postSpeech({
        // setting headers per request
        body: {
          input: encodedString,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (error) {
        toast({
          title: "Une erreur est survenue lors de l'enregistrement",
          description: error?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return 'error 1';
      }
      return (data);
    } catch (e) {
      toast({
        title: "Une erreur est survenue lors de l'enregistrement",
        description: e?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return 'error 2';
    }
  };

  // ! settings
  const MIMETYPE = "audio/wav";
  const CHUNK_TIME_IN_MS = 10000; // no chunk if undefined

  const handleToggleRecording = (event) => {
    event.preventDefault();
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioStream(stream);
        // ! add options here if needed (like audioBits per second)
        const mediaRecorder = new MediaRecorder(stream);
        let audio = [];
        let encodedBlobs = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audio = [...audio, event.data];
            const b = new Blob([event.data], { type: MIMETYPE });
            const base64 = blob2base64(b, MIMETYPE)
              .then((a) => onSpeech(a));
            encodedBlobs = [...encodedBlobs, base64];
          }
        };

        mediaRecorder.onstop = async () => {
          Promise.all(encodedBlobs).then((stringArray) => {
            setText(stringArray.join(' '));
          });
        };

        mediaRecorder.start(CHUNK_TIME_IN_MS);
        setIsRecording.on();
      })
      .catch((error) => {
        toast({
          title: "Impossible d'accèder au microphone.",
          description: error?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      });
  };

  const stopRecording = async () => {
    audioStream.getAudioTracks().forEach(track => {
      track.stop();
    });
    setIsRecording.off();
  };

  const getPopoverHeight = () => {
    if (popoverState === PopoverState.REDUCED) return "56px";
    if (popoverState === PopoverState.FULL) return "100%";

    return "50%";
  };

  return (
    <Box w="100vw" bg="gray.100">
      <Image src={background} w="full" />

      <Button
        position="fixed"
        minW="56px"
        bottom="24px"
        left="38px"
        variant="outline"
        bg="white"
        onClick={() => setPopoverState(PopoverState.OPEN)}
        boxShadow="base"
      >
        <AIIcon w="24px" h="24px" color={IA_COLOR} />
        {isRecording && (<RecordingIcon w="18px" h="18px" ml="8px" />)}
      </Button>

      {popoverState !== PopoverState.CLOSED && (
        <Box
          bg="white"
          position="fixed"
          bottom="0px"
          left={popoverState === PopoverState.FULL ? "0px" : "280px"}
          h={getPopoverHeight()}
          w={popoverState === PopoverState.FULL ? "100%" : "50%"}
          borderTopRadius="8px"
          onClick={popoverState === PopoverState.REDUCED
            ? () => setPopoverState(PopoverState.OPEN)
            : undefined
          }
          cursor={popoverState === PopoverState.REDUCED
            ? 'pointer'
            : undefined}
          boxShadow="2xl"
        >
          <Flex
            bg={IA_BACKGROUND_COLOR}
            color={IA_ALT_COLOR}
            p="16px"
            borderTopRadius="8px"
            justify="space-between"
          >
            <Flex gap="8px" alignItems="center">
              <AIIcon w="24px" h="24px" color={IA_ALT_COLOR} />
              <Text fontWeight={600}>
                Visite assistée par IA
              </Text>
              {popoverState === PopoverState.REDUCED && isRecording && (
                <RecordingIcon w="18px" h="18px" ml="8px" />
              )}
            </Flex>

            <ButtonGroup>
              <IconButton
                size="xs"
                variant="ghost"
                icon={<MinusIcon />}
                onClick={() => setPopoverState(PopoverState.REDUCED)}
              />
              <IconButton
                size="xs"
                variant="ghost"
                icon={popoverState === PopoverState.FULL
                  ? <ReduceIcon />
                  : <WidenIcon />
                }
                onClick={() => setPopoverState(
                  popoverState === PopoverState.FULL
                    ? PopoverState.OPEN
                    : PopoverState.FULL
                )}
              />
              <IconButton
                size="xs"
                variant="ghost"
                icon={<CloseIcon />}
                onClick={() => setPopoverState(PopoverState.CLOSED)}
              />
            </ButtonGroup>
          </Flex>
          <AIBody
            token={token}
            isRecording={isRecording}
            setIsRecording={handleToggleRecording}
            text={text}
            setText={setText}
          />
        </Box>
      )}
    </Box>
  )
}

export default AIPage;
