// packages
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Global, css } from '@emotion/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Root from './Root';

const theme = extendTheme({
  fonts: {
    heading: `'Work Sans', sans-serif`,
    body: `'Work Sans', sans-serif`,
  },
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Global
        styles={css`
            @import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
          `}
      />
      <Root />
    </ChakraProvider>
  );
}

export default App;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
